@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";

.homeApp {
  background-color: $light-gray5;
  display: flex;
  flex-direction: column;
  padding: 4%;
  color: $white;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;

  &.mobile {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    align-items: center;
    justify-content: flex-start;

    .middleContainer {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      justify-content: flex-start;
    }

    .pageTitle {
      font-size: 20px;
      margin-bottom: 15px;
    }

    .item {
      width: 180px;
      height: 80px;
    }

    .itemMetadata {
      margin-left: 8px;
      margin-top: 4px;
      font-size: 16px;
    }

    .bottomContainer {
      flex-direction: column;
      flex: 1 1 auto;
    }
  }
}

.backgroundImage {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 1 1 auto;
  max-height: 70%;
  border-radius: 30px;
  border: 5px solid $white;
}

.pageTitle {
  font-size: 48px;
  letter-spacing: 2px;
  margin-bottom: 40px;
  color: $light-gray2;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Lato";
  letter-spacing: 4px;
}

.bottomContainer {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: auto;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 200px;
}

.middleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  .item {
    width: 380px;
    height: 200px;
    background-origin: content-box;
    padding: 15px;
  }

  .itemMetadata {
    font-size: 22px;
    margin-left: 10px;
    margin-top: 6px;
  }
}

:global(.bp3-button-text) {
  color: $white;
  font-family: "Lato";
}

.itemContainer {
  margin: 20px;
  display: flex;
  flex-direction: column;
  border: 2px solid $gray1;
  border-radius: 14px;
  display: flex;
  padding: 10px;
  background-color: $white;
  position: relative;

  &:hover {
    text-decoration: none;
    border: 2px solid $blue5;

    .itemMetadata {
      color: $blue5;
    }
  }
}

.item {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 200px;
  height: 100px;
}

.itemMetadata {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 8px;
  margin-top: 4px;
  font-size: 16px;
  font-family: "Oswald";
  font-weight: 600;
  color: $light-gray1;
}
