@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "../../App.module.scss";

.resultListBody {
  .title {
    font-family: "proxima_nova_altbold";
    font-size: 16px;
    margin-bottom: 10px;
    text-transform: unset;
    color: $white;
  }
}
