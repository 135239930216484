@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";

.scottflixBody {
  flex: 0 1 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // border: 2px solid blue;
}
