@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "../../App.module.scss";

.showPreviewBody {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  letter-spacing: 0.02em;

  // @media (max-width: $mobile-max-size) {
  //   margin-bottom: 50px;
  // }

  .previewContainer {
    flex: 1 1 auto;
    display: flex;
    width: 100%;
    flex-direction: row;
    background-color: #f8f8f8;

    @media (max-width: $mobile-max-size) {
      flex-direction: column;
    }

    &:hover {
      box-shadow: 0 4px 16px rgb(0 0 0 / 40%);
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }

    .previewMetadata {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 20px;
      min-width: 30%;
      max-width: 30%;

      @media (max-width: $mobile-max-size) {
        min-width: unset;
        max-width: 100%;
        margin: 16px;
        flex: 0 0 auto;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
    }

    .showImage {
      flex: 1 1 auto;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      min-width: 65%;

      @media (max-width: $mobile-max-size) {
        width: 100%;
      }
    }
  }

  .featuredShowContainer {
    display: flex;
    height: 75%;
    margin: 40px;
    flex: 0 0 auto;
    flex-direction: row;

    @media (max-width: $mobile-max-size) {
      margin: 20px;
      height: 65%;
      flex-direction: column;
      word-break: break-all;
    }
  }

  .secondaryShowContainer {
    margin: 0 40px 40px 40px;
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;

    @media (max-width: $mobile-max-size) {
      flex-direction: column;
      margin: 0 20px 20px 20px;
    }

    .showContainer {
      height: 350px;
      flex: 1 1 auto;
      display: flex;

      &:not(:first-child) {
        margin-left: 40px;

        @media (max-width: $mobile-max-size) {
          margin-top: 20px;
          margin-left: 0px;
        }
      }
      .previewContainer {
        &:hover {
          box-shadow: none;
          cursor: default;
        }

        .previewMetadata {
          min-width: 28%;
          max-width: 28%;
          @media (max-width: $mobile-max-size) {
            min-width: unset;
            max-width: unset;
          }

          .tags {
            .tag {
              font-size: 10px;
              font-weight: 500;
              padding: 4px 6px;
              border-radius: 4px;
            }
          }

          .title {
            font-size: 14px;
            font-weight: 600;
            margin-top: 8px;
          }

          .subtitle {
            font-size: 12px;
            margin-top: 6px;
            line-height: 16px;
          }

          .action {
            line-height: 18px;
            font-size: 10px;
            font-weight: 500;
            margin-top: 12px;
          }
        }
      }
    }
  }
}

.tags {
  display: flex;
  flex-direction: row;

  .tag {
    flex: 0 0 auto;
    color: #fff;
    background: #eb0028;
    font-size: 16px;
    font-weight: 700;
    padding: 6px 8px;
    border-radius: 4px;

    @media (max-width: $mobile-max-size) {
      font-size: 10px;
      font-weight: 700;
      padding: 3px 4px;
    }
  }
}

.title {
  font-size: 28px;
  font-weight: 600;
  margin-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: $mobile-max-size) {
    font-size: 18px;
    margin-top: 6px;
  }
}

.subtitle {
  font-size: 18px;
  margin-top: 8px;
  line-height: 22px;
  color: #b1b1bd;
  font-family: "OpenSans-Medium";

  @media (max-width: $mobile-max-size) {
    font-size: 12px;
    margin-top: 3px;
    line-height: 14px;
  }
}

.action {
  line-height: 23px;
  font-size: 18px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 16px;
  font-family: "OpenSans-Bold";
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: $mobile-max-size) {
    line-height: 16px;
    font-size: 12px;
    margin-top: 8px;
  }

  .actionIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3px;

    @media (max-width: $mobile-max-size) {
      margin-right: 2px;
    }
  }
}
