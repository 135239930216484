@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";

.instructorView {
    flex: 1 1 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $light-gray5;
    color: $dark-gray3;
    font-family: "BrandonText-Bold";
    overflow-y: auto;

  &.mobile {
    padding: 0px;
    // height: 85%;

    .mobileIntroImageContainer {
        width: 100%;
        display:flex;
        align-items: center;
        justify-content: center;
        padding: 20px;

        .mobileIntroImageContainer {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: 10px;
            height: 300px;
            
        }
    }

    .mobileIntroTextContainer {
        width: 100%;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #F5F7F9;
        padding: 15px 20px 60px 20px;

        .mobileTitle {
            color: $black;
            font-family: "BrandonText-Bold";
            font-size: 26px;
            letter-spacing: 2px;
            margin-bottom: 10px;
            text-transform: uppercase;
        }
    
        .mobileSubtitle {
            color: rgb(101, 102, 106);
            font-family: "BrandonText-Bold";
            font-size: 13px;
            letter-spacing: 2px;
            margin-bottom: 22px;
            text-transform: uppercase;
        }
    
        .mobileText {
            color: #181a1d;
            font-family: "BrandonText-Light";
            font-size: 14px;
            text-transform: none;
            letter-spacing: 0px;
            line-height: 1.5;
            white-space: pre-wrap;
            text-align: center;
        }
    }


    .mobileQuoteContainer {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        letter-spacing: 2px;
        font-family: "BrandonText-Light";
        font-size: 18px;
        flex-direction: column;
        text-transform: uppercase;
        padding: 10%;
        min-height: 300px;
        width: 100%;
        flex: 0 0 auto;

        .quoteSymbol {
            font-size: 80px;
            font-family: "Georgia";
            margin-bottom: -30px;
        }
    
        .instructorQuote {
            margin-bottom: 15px;
            text-align: center;
            white-space: pre-wrap;
        }
        
        .instructorName {
            font-family: "BrandonText-Bold";
            font-size: 12px;
        }
    }
  }
}

.instructorViewContainer {
    height: 95%;
    width: 100%;
    flex: 0 0 auto;
}

.quoteContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: $dark-gray1;
    color: $white;
    letter-spacing: 2px;
    font-family: "BrandonText-Light";
    font-size: 30px;
    flex-direction: column;
    text-transform: uppercase;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 10%;

    .quoteSymbol {
        font-size: 200px;
        font-family: "Georgia";
        margin-bottom: -120px;
    }

    .instructorQuote {
        margin-bottom: 15px;
        text-align: center;
        white-space: pre-wrap;
    }
    
    .instructorName {
        font-family: "BrandonText";
        font-size: 12px;
    }
}

.introContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 10%;
}

.introImageContainer {
    height: 100%;
    flex: 1 1 auto;
    margin-right: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 10px;
    max-width: 45%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.contain {
        background-size: contain;
    }
}

.introTextContainer {
    max-width: 50%;
    height: 100%;
    flex: 1 1 auto;
    font-family: "BrandonText";
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;

    .title {
        color: $black;
        font-family: "BrandonText-Bold";
        font-size: 50px;
        letter-spacing: 4px;
        margin-bottom: 10px;
    }

    .subtitle {
        color: rgb(101, 102, 106);
        font-family: "BrandonText-Bold";
        font-size: 14px;
        letter-spacing: 4px;
        margin-bottom: 30px;
    }

    .text {
        color: #181a1d;
        font-family: "BrandonText-Light";
        font-size: 16px;
        text-transform: none;
        letter-spacing: 0px;
        line-height: 1.6;
        max-width: 70%;
        white-space: pre-wrap;
    }
}