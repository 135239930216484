@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "./ScottifyVariables.module.scss";

.trackRowMobile {
  min-height: 40px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;

  .rowTop {
    flex: 0 1 auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .rowBottom {
    flex: 0 1 auto;
    width: 100%;
  }

  .rowIcon {
    display: flex;
  }

  .trackTitle {
    font-family: "Proxima Nova";
    color: $white;
    font-size: 12px;
    flex: 1 1 auto;
  }

  .trackArtist {
    color: $spot-gray;
    font-size: 10px;
    font-family: "Proxima Nova";
    display: flex;
    flex-direction: row;
    align-items: center;

    .artistDot {
      margin: 0 1px;
    }
  }
}
