@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "./ScottifyVariables.module.scss";

.scottifyPage {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: $spot-bg;
  color: $spot-gray;
  // border: 2px solid orange;

  &.mobile {
    padding: 0px;

    .scottifyPageHeader {
      height: 40px;
      min-height: 40px;

      .rosterLogoImage {
        height: 24px;
      }

      .rivalLogoImage {
        height: 25px;
        margin-right: 10px;
      }
    }

    > .scottifyPageFooter {
      height: 100px;
    }
  }
}

.scottifyPageBody {
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.scottifyPageHeader {
  height: 80px;
  flex: 0 0 auto;
  width: 100%;
  background-color: $spot-bg-light;
  display: flex;
  flex-direction: row;
  align-content: center;

  .webNavBarImage {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rosterLogoImage {
    height: 46px;
    margin-left: 15px;
  }

  .rivalLogoImage {
    height: 40px;
    margin-right: 20px;
  }

  .horizontalSpacer {
    flex: 1 1 auto;
  }
}

.scottifyPageFooter {
  height: 100px;
  flex: 0 0 auto;
  width: 100%;
  background-color: $spot-bg-light;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
