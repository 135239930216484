@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";

.contestantsList {
  padding: 10px 40px 40px 40px;
  width: 100%;
  overflow-x: hidden;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;

  .contestantTile {
    width: 250px;
    margin: 18px;
    display: flex;
    flex-direction: column;
    background-color: #f8f8f8;

    &:hover {
      box-shadow: 0 4px 16px rgb(0 0 0 / 40%);
      transition: box-shadow 0.3s ease-in-out;
      cursor: pointer;

      .tilePic {
        span::after {
          content: "";
          position: absolute;
          bottom: 4px;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            rgba(24, 24, 24, 0) 0%,
            rgba(24, 24, 24, 0.2) 100%
          );
        }
      }
    }
  }

  .tileMetadata {
    margin: 0px;
    width: 100%;
    display: flex;
    height: 100px;
    padding: 6%;
    font-family: "OpenSans-Light";

    .tileName {
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }
  }

  .tilePic {
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
    position: relative;

    img {
      flex: 1 1 auto;
      object-fit: contain;
      width: 100%;
    }

    span::after {
      content: "";
      position: absolute;
      bottom: 4px;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        rgba(24, 24, 24, 0) 0%,
        rgba(24, 24, 24, 0.7) 100%
      );
    }
  }
}
