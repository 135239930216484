@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "../../App.module.scss";

.showDetailsDialog {
  border-radius: 10px;
  width: 65%;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  position: relative;

  :global(.bp5-dialog-header) {
    display: none;
  }

  @media (max-width: $mobile-max-size) {
    width: 90%;
  }
}

.header {
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  z-index: 10;

  @media (max-width: $mobile-max-size) {
    padding: 6px;
  }

  .closeButton {
    background-color: #181818;
    border-radius: 50%;
    height: 36px;
    padding: 8px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $mobile-max-size) {
      height: 24px;
      padding: 4px;
      width: 24px;
    }

    :hover {
      cursor: pointer;
    }
  }
}

.showDetails {
  background-color: #181818;
  border-radius: 9px;
}

.showImageContainer {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  border-radius: 9px 9px 0 0;
  position: relative;

  @media (max-width: $mobile-max-size) {
    height: 250px;
  }

  &:global(.breakingBad) {
    background-position: top;
  }

  &:global(.wingedMigration) {
    background-position-y: 40%;
  }

  &:global(.wildBabies) {
    background-position-y: 20%;
  }

  &:global(.workinMoms) {
    background-position-y: 20%;
  }

  &:global(.gossipGirl) {
    background-position-y: 20%;
  }

  &:global(.blingEmpire) {
    // background-position-y: 40%;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:global(.emilyInParis) {
    background-position-y: 80%;
  }

  &:global(.pawPatrol) {
    background-position-x: left;
  }
}

.imageOverlay {
  height: 500px;
  // border: 2px solid gold;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(0deg, #181818, transparent 50%);

  @media (max-width: $mobile-max-size) {
    height: 250px;
  }
}

.showLogo {
  // border: 2px solid maroon;
  position: absolute;
  bottom: 90px;
  left: 40px;
  width: 300px;
  height: 150px;
  display: flex;
  flex-direction: row;
  z-index: 3;
  justify-content: flex-start;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: $mobile-max-size) {
    bottom: 30px;
    left: 20px;
    width: 100px;
    height: 60px;
  }

  // img {
  //   padding: 10px 0 0 0;
  //   flex: 1 1 auto;
  //   object-fit: contain;
  //   width: 100%;
  // }
}

.showDescription {
  font-size: 14px;
  line-height: 20px;
  color: #d2d2d2;
  text-align: justify;
  padding: 40px;
  z-index: 10;
  position: relative;
  border-top: 1px solid #404040;
  background-color: #333;
  margin: 40px;
  border-bottom: 1px solid #404040;

  @media (max-width: $mobile-max-size) {
    padding: 15px;
    margin: 20px;
    font-size: 12px;
  }
}

.showInfo {
  // border: 2px solid greenyellow;
  margin-top: 20px;
  padding: 0 40px;
  font-size: 16px;
  line-height: 30px;

  @media (max-width: $mobile-max-size) {
    margin-top: 15px;
    padding: 0 15px;
    font-size: 14px;
    line-height: 20px;
  }

  .title {
    color: #777;
  }

  .description {
    color: #fff;
  }
}

.showButtons {
  height: 50px;
  margin-top: -75px;
  z-index: 200;
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 0 40px;
  align-items: center;
  justify-content: flex-start;

  // &:hover {
  //   cursor: pointer;
  // }

  @media (max-width: $mobile-max-size) {
    height: 25px;
    padding: 0 20px;
    margin-top: -10px;
  }
}

.playButton {
  background-color: white;
  color: black;
  padding: 5px 20px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  border-radius: 5px;
  align-items: center;
  justify-content: center;

  @media (max-width: $mobile-max-size) {
    padding: 5px 10px;
    margin-right: 5px;
  }
}

.playIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 5px;

  @media (max-width: $mobile-max-size) {
    margin-right: 3px;
    height: 20px;
    width: 20px;
  }
}

.playText {
  font-size: 16px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;

  @media (max-width: $mobile-max-size) {
    font-size: 14px;
  }
}
