@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "../../App.module.scss";

.featuredShowContainer {
  display: flex;
  width: 100%;
  position: relative;
  height: 70%;
  flex-direction: column;
  font-family: Helvetica Neue, Segoe UI, Roboto, Ubuntu, sans-serif;

  @media (max-width: $mobile-max-size) {
    height: 40%;
  }
}

.featuredShowBackground {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 1 1 auto;
}

.featuredShowBackgroundOverlay {
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, #121212, transparent 85%);
  position: absolute;
}

.featuredShowMetadataContainer {
  position: absolute;
  bottom: 50px;
  left: 50px;
  flex: 1 1 auto;
  flex-direction: column;

  @media (max-width: $mobile-max-size) {
    bottom: 20px;
    left: 20px;
  }
}

.logoContainer {
  height: 32px;
  display: flex;
  flex: 1 1 auto;
  object-fit: contain;

  @media (max-width: $mobile-max-size) {
    height: 16px;
  }
}

.textContainer {
  flex: 1 1 auto;
  padding: 5px 0 20px 0;
  display: flex;
  flex-direction: column;

  @media (max-width: $mobile-max-size) {
    padding: 5px 0 10px 0;
  }

  .subtitleText {
    color: $white;
    text-align: left;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 36px;

    @media (max-width: $mobile-max-size) {
      font-size: 16px;
    }
  }

  .subtitleIcons {
    text-align: left;
    padding-top: 15px;
    font-weight: 600;

    @media (max-width: $mobile-max-size) {
      padding-top: 5px;
    }

    .match {
      color: $green3;
      margin-right: 10px;
    }
    .year {
      color: $white;
      margin-right: 10px;
    }

    .rating {
      color: $white;
      margin-right: 10px;
      border: 1px solid $white;
      padding: 0 4px;
      font-weight: 400;
    }
    .seasons {
      color: $white;
      font-weight: 400;
    }
  }
}
