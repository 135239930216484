@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";

.mobileHeader {
  height: 48px;
  box-shadow: 0 2px 4px 0 rgb(216 216 216 / 50%);
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;

  .headerLogo {
    width: 60px;
    margin: 0 14px;
    display: flex;
    align-items: center;

    .headerLogoImage {
      height: 30px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .headerSpacer {
    flex: 1 1 auto;
  }

  .aloneMobileHeaderMenuPopover {
    margin-right: 10px;
  }
}

.aloneMobileHeaderNavBarMenu {
  min-width: 100px;
}
