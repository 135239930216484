@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";

$nf-black: #000000;
$nf-bg: #121212;
$nf-bg-light: #272727;
$nf-gray: #b3b3b3;
$nf-red: #e50914;

.scottflixPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: $nf-bg;
  color: $nf-gray;

  &.mobile {
    padding: 0px;

    > .scottflixPageHeader {
      height: 60px;
      padding: 0 10px;

      .scottflixLogoImage {
        height: 50px;
        margin-left: 0;
        margin-right: 0;
      }

      .menuPopover {
        display: flex;
        align-items: center;
      }
    }
  }
}

.scottflixPageHeader {
  height: 80px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  position: absolute;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0)
  );
  z-index: 20;

  .webNavBarImage {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .webNavBarText {
    font-size: 14px;
    color: $white;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 20px;
    font-family: "Roboto", sans-serif;

    &:hover {
      color: $light-gray2;
      cursor: pointer;
    }
  }

  .scottflixLogoImage {
    height: 60px;
    margin-left: 30px;
    margin-right: 34px;
  }

  .horizontalSpacer {
    flex: 1 1 auto;
  }
}

.mobileNavBarMenu {
  background-color: $black !important;
  border: 1px solid $dark-gray4;
}

.mobileNavBarItem {
  font-family: "Oswald", sans-serif;
  letter-spacing: 2px;
  text-align: center;
}

.scottflixPageBody {
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
}
