@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";

.pelotonRules {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;

  &.mobile {
    > .rulesBody {
      width: 100%;
      margin-top: 0px;
      padding-top: 10px;
      overflow-y: auto;
      margin-bottom: unset;
      justify-content: flex-start;

      > .ruleContainer {
        margin: 15px 20px;

        .ruleImageContainer {
          margin-right: 15px;
          width: 30px;
          height: 30px;
        }

        .ruleText {
          font-size: 9px;
          display: flex;
          flex-direction: column;

          .ruleHeader {
            font-weight: bold;
            font-family: "BrandonText-Bold";
            color: rgb(24, 26, 29);
            border: 1px solid rgb(24, 26, 29);
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px 2px 0 0;
            width: 100%;
            text-transform: uppercase;
            letter-spacing: 2px;
            writing-mode: lr;
            background-color: $white;
            padding: 1px;
            font-size: 8px;
          }

          .ruleDescription {
            padding: 10px;
          }

          &:hover {
            transform: unset;
          }
        }
      }
    }
  }

  .rulesBody {
    flex: 1 1 auto;
    margin-top: 50px;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 40px;

    .ruleContainer {
      display: flex;
      flex-direction: row;
      align-items: center;

      &:hover {
        .ruleImageContainer {
            background-color: rgb(223, 28, 47);
            border: 2px solid rgb(223, 28, 47);
        }

        .ruleHeader {
            border: 1px solid rgb(223, 28, 47);
            color:rgb(223, 28, 47);
        }
      }

      .ruleImageContainer {
        border: 2px solid $black;
        background-color: $black;
        width: 80px;
        height: 80px;
        margin-right: 40px;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;

        .ruleImage {
          flex: 0 0 auto;
          width: 70%;
        }
      }

      .ruleHeader {
        font-weight: bold;
        font-family: "BrandonText-Bold";
        font-size: 10px;
        color: $black;
        border: 1px solid $black;
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px 0 0 2px;
        text-transform: uppercase;
        text-orientation: upright;
        writing-mode: vertical-rl;
        background-color: $white;
        font-weight: 700;
        padding: 3px;
      }

      .ruleText {
        background-color: rgb(245, 247, 249);
        // border: 3px solid $black;
        text-align: center;
        // background-color: $dark-gray1;
        border-radius: 5px;
        transition: transform 0.5s;
        font-family: "BrandonText";
        font-weight: 500;
        letter-spacing: 0.5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1 1 auto;

        .ruleDescription {
          padding: 20px;
        }

      }
    }
  }
}
