@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";

.pelotonHome {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: $light-gray5;
  color: $dark-gray3;
  font-family: "BrandonText-Bold";

  &.mobile {
    padding: 0px;

    .header {
      height: 50px;
      display: flex;

      .headerLogo {
        margin: 0 10px;
        width: unset;

        .headerLogoImage {
          height: 25px;
        }
      }

      .navContainer {
        margin-left: 20px;
        display: flex;
        flex: 1 1 auto;
        justify-content: space-around;
        margin-right: 10px;

        .navItem {
          width: unset;
          font-size: 12px;
        }
      }
    }

    .headlineImageContainer {
      height: 35%;
      font-size: 22px;
    }

    .categories {
      padding: 10px 0 12px 0;
      font-size: 10px;
      border-bottom: 1px solid rgb(0 0 0 / 8%);
      // box-shadow: rgb(0 0 0 / 8%) 0px 0.25rem 1rem 0.25rem;

      .category {
        padding: 0 10px;
      }
    }

    .instructors {
      padding: 0px 20px;
      width: 100%;
      overflow-x: hidden;

      .instructorTile {
        width: 100%;
        height: 150px;
        // flex: 0 0 auto;
        margin: 10px;
        display: flex;

        .tileMetadata {
          margin: 0px;
          width: 50%;
          display: flex;
          padding-left: 20px;

          .tileName {
            font-size: 12px;
          }
          .tileType {
            font-size: 10px;
          }
        }

        .tilePic {
          width: 50%;
          display: flex;
          justify-content: center;
          flex: 1 1 auto;

          &.shiftLeftAnna {
            align-items: flex-end;

            img {
              padding: 10px 0 0 0;
              width: 100%;
              object-fit: contain;
            }
          }

          &.shiftLeftNutmeg {
            justify-content: flex-start;

            img {
              padding: 10px 0 0 0;
              width: 100%;
              object-fit: contain;
            }
          }

          img {
            // flex: 0 0 auto;
            padding: 10px 0 0 0;
            flex: 1 1 auto;
            object-fit: contain;
            width: 100%;
          }
        }
      }
    }
  }
}

.header {
  height: 72px;
  background-color: rgb(24, 26, 29);
  font-size: 20px;
  color: $white;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;

  .headerLogo {
    width: 200px;
    margin: 0 32px;
    display: flex;
    align-items: center;

    .headerLogoImage {
      height: 35px;
    }
  }

  .navContainer {
    flex: 1 1 auto;
    display: flex;
    margin-left: 100px;
    margin-right: 200px;
    color: $white;
    font-family: "BrandonText";
    align-items: center;
  }
}

.body {
  flex: 1 1 auto;
  overflow-y: auto;
  justify-content: center;
}

.headlineImageContainer {
  height: 70%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-family: "BrandonText-Light";
  font-size: 50px;
}

.categories {
  display: flex;
  justify-content: center;
  padding: 40px 0 0 0;
  color: rgb(24, 26, 29);
  font-family: "BrandonText-Bold";
  font-size: 12px;
  background-color: $white;
}

.category {
  padding: 0 40px;
  letter-spacing: 2px;

  &.selected {
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
    color: rgb(24, 26, 29);
  }

  &:not(.selected) {
    color: rgb(101, 102, 106);
  }

  &:hover {
    cursor: pointer;
    &:not(.selected) {
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-thickness: 2px;
      text-underline-offset: 4px;
    }
  }
}

.instructors {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  padding: 20px 20%;
  color: rgb(24, 26, 29);
  font-family: "BrandonText-Bold";
  font-size: 16px;
  background-color: $white;
  flex-wrap: wrap;
  overflow-y: auto;
  padding-bottom: 70px;
}

.instructorTile {
  background-color: rgb(245, 247, 249);
  width: 392px;
  height: 200px;
  flex: 0 0 auto;
  margin: 10px;
  display: flex;
  flex-direction: row;
  position: relative;

  &:hover {
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 8%) 0px 0.25rem 1rem 0.25rem;
  }
}

.tilePic {
  width: 50%;
  display: flex;
  justify-content: center;
  flex: 1 1 auto;

  &.shiftLeftAnna {
    justify-content: flex-start;

    img {
      padding: 20px 0 0 0;
      width: 100%;
    }
  }

  &.shiftLeftNutmeg {
    justify-content: flex-start;
    // align-items: flex-start;

    img {
      padding: 20px 20px 0 0;
      width: 100%;
    }
  }

  img {
    flex: 0 0 auto;
    padding: 20px 20px 0 20px;
  }
}

.tileMetadata {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  color: rgb(101, 102, 106);
  letter-spacing: 1px;
  justify-content: center;
  margin: 0 40px 0 20px;

  .tileName {
    font-family: "BrandonText-Bold";
    font-size: 16px;
    margin-bottom: 6px;
    text-transform: uppercase;
  }

  .tileType {
    font-family: "BrandonText-Light";
    font-size: 12px;
    text-transform: uppercase;
  }
}

.footer {
  height: 100px;
  background-color: rgb(245, 247, 249);
}

.navItem {
  width: 120px;
  font-family: "BrandonText-Bold";
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;

  &:hover {
    color: $gray5;
    cursor: pointer;
  }
}

.guestTag {
  position: absolute;
  top: 10px;
  right: 10px;
  text-transform: uppercase;
  background-color: rgb(255, 255, 255);
  color: rgb(101, 102, 106);
  letter-spacing: 1px;
  padding: 3px 11px;
  line-height: 1.5em;
  font-size: 10px;
  font-family: "BrandonText";
  border-radius: 10px;
}
