@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "../../App.module.scss";

.title {
  font-family: "proxima_nova_altbold";
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 10px;
  text-transform: capitalize;
  color: $white;

  @media (max-width: $mobile-max-size) {
    font-size: 16px;
    margin-bottom: 8px;
  }
}

.result {
  background-color: $white;
  border-radius: 3px;
  padding: 20px;
  margin: 10px;

  @media (max-width: $mobile-max-size) {
    margin: 0;
  }
}

.name {
  font-family: "proxima_nova_altbold";
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 10px;
  text-transform: capitalize;

  @media (max-width: $mobile-max-size) {
    font-size: 16px;
    margin-bottom: 8px;
  }
}

.bio {
  font-family: "Proxima Nova";
  font-size: 14px;
  // line-height: 24px;
  margin-bottom: 20px;
  white-space: pre-wrap;

  @media (max-width: $mobile-max-size) {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.primaryPicSection {
  width: 100%;
  display: flex;
  height: 400px;
  // font-family: "Proxima Nova";
  // padding: 0 25%;
  // display: flex;
  // flex-direction: column;
  // flex: 0 0 auto;
  // margin-bottom: 10px;
  // align-items: center;

  @media (max-width: $mobile-max-size) {
    padding: 0px;
  }
}

// .section {
//   font-family: "OpenSans-Light";
//   padding: 0 25%;
//   display: flex;
//   flex-direction: column;
//   flex: 0 0 auto;
//   margin-bottom: 10px;

//   @media (max-width: $mobile-max-size) {
//     padding: 0 10%;
//   }
// }

// .centeredSection {
//   align-items: center;
//   text-align: center;
// }

// .name {
//   font-family: "OpenSans-SemiBold";
//   text-transform: uppercase;
//   font-size: 20px;
//   margin-bottom: 2px;

//   @media (max-width: $mobile-max-size) {
//     font-size: 16px;
//   }
// }

// .season {
//   font-family: "OpenSans";
//   font-size: 15px;
//   color: #999;
//   margin-bottom: 20px;

//   @media (max-width: $mobile-max-size) {
//     font-size: 12px;
//     margin-bottom: 10px;
//   }
// }

// .bio {
//   font-family: "OpenSans";
//   font-size: 16px;
//   line-height: 24px;
//   margin-bottom: 20px;
//   white-space: pre-wrap;

//   @media (max-width: $mobile-max-size) {
//     font-size: 12px;
//     line-height: 16px;
//     margin-bottom: 10px;
//   }
// }

// .info {
//   margin-left: 5px;
// }

// :global(span.bp4-popover2-target) {
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .sectionHeader {
//   font-family: "OpenSans-Bold";
//   text-transform: uppercase;
//   font-size: 12px;
//   margin-bottom: 4px;
//   display: flex;
//   align-items: center;
// }

// .itemsList {
//   font-family: "OpenSans-Light";
//   font-size: 14px;
//   line-height: 24px;
//   margin-bottom: 20px;
//   white-space: pre-wrap;

//   @media (max-width: $mobile-max-size) {
//     font-size: 12px;
//     line-height: 18px;
//     margin-bottom: 10px;
//   }

//   .itemStrike {
//     text-decoration: line-through;
//   }
// }

// .primaryPicSection {
//   font-family: "OpenSans-Light";
//   padding: 0 25%;
//   display: flex;
//   flex-direction: column;
//   flex: 0 0 auto;
//   margin-bottom: 10px;
//   align-items: center;

//   @media (max-width: $mobile-max-size) {
//     padding: 0px;
//   }
// }

// .secondaryPicSection {
//   font-family: "OpenSans-Light";
//   padding: 0 25%;
//   display: flex;
//   flex-direction: row;
//   flex: 0 0 auto;
//   margin-bottom: 60px;
//   align-items: center;

//   :not(:last-child) {
//     margin-right: 30px;
//   }

//   @media (max-width: $mobile-max-size) {
//     flex-direction: column;
//     width: 100%;
//     padding: 0px;

//     :not(:last-child) {
//       margin-right: 0px;
//     }
//   }
// }

.pic {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 4px;
  flex: 1 1 auto;
}
