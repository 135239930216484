@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "./ScottifyVariables.module.scss";

.scottifyRules {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.mobile {
    > .rulesBody {
      width: 100%;
      margin-top: 60px;
      overflow-y: auto;
      margin-bottom: unset;
      justify-content: flex-start;

      > .ruleContainer {
        margin: 15px 20px;

        .ruleImageContainer {
          margin-right: 30px;
          width: 60px;
          height: 60px;
        }

        .ruleText {
          font-size: 8px;

          .ruleDescription {
            padding: 5px;
          }

          &:hover {
            transform: unset;
            border: 3px solid $dark-gray4;
            background-color: $dark-gray3;
          }
        }
      }
    }
  }

  .rulesBody {
    flex: 1 1 auto;
    // margin-top: 80px;
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 40px;

    .ruleContainer {
      display: flex;
      flex-direction: row;
      align-items: center;

      .ruleImageContainer {
        border: 2px solid $white;
        width: 80px;
        height: 80px;
        margin-right: 40px;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;

        .ruleImage {
          flex: 0 0 auto;
          width: 70%;
        }
      }

      .ruleHeader {
        font-weight: bold;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        color: $white;
        flex: 1 1 auto;
        display: flex;
        border-radius: 3px 0 0 0;
        text-transform: uppercase;
        background-color: $spot-green;
        font-weight: 700;
        padding: 5px 10px;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      .ruleText {
        border: 3px solid $spot-green;
        text-align: center;
        // background-color: $dark-gray1;
        border-radius: 5px;
        transition: transform 0.5s;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        letter-spacing: 0.5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        color: $white;

        .ruleDescription {
          padding: 20px;
        }

        &:hover {
          transform: scale(1.05);
          // border: 3px solid $dark-gray4;
          // background-color: $dark-gray3;
        }
      }
    }
  }
}
