@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "../../App.module.scss";

.showsListContainer {
  padding: 0 10px 10px 10px;

  .title {
    color: #e5e5e5;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 10px;
  }

  .list {
    display: flex;
    flex-direction: row;

    @media (max-width: $mobile-max-size) {
      overflow-x: scroll;
    }
  }
}
