@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "../../App.module.scss";

.buzzFeedHome {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: $dark-gray3;
  font-family: "Proxima Nova";
  height: 100%;
  width: 100%;
}

.header {
  height: 72px;
  box-shadow: 0 2px 4px 0 rgb(216 216 216 / 50%);
  background-color: #fff;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;
  margin-bottom: 5px;

  .headerLogo {
    width: 120px;
    margin: 0 18px;
    display: flex;
    align-items: center;

    .headerLogoImage {
      height: 20px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .navContainer {
    flex: 1 1 auto;
    display: flex;
    margin-right: 200px;
    color: $white;
    font-family: "Proxima Nova";
    align-items: center;
  }
}

.body {
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
}

.bodyHeader {
  flex: 0 0 auto;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 22px;
  font-weight: 600;
  margin-top: 25px;
  color: #000;
  font-family: "Proxima Nova";
  position: relative;

  @media (max-width: $mobile-max-size) {
    padding: 0px;
    margin-top: 10px;
    font-size: 14px;
  }

  .title {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 8px;
    border-bottom: 4px solid #eb0028;

    @media (max-width: $mobile-max-size) {
      padding-bottom: 4px;
      border-bottom: 2px solid #eb0028;
    }
  }

  .leftHeader {
    opacity: 0.2;
    flex: 1 1 auto;
    margin-left: 40px;
  }

  .rightHeader {
    opacity: 0.2;
    flex: 1 1 auto;
    margin-right: 40px;
  }
}

.navItem {
  width: 100px;
  font-family: "proxima_nova_altbold";
  font-size: 16px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    color: rgb(15, 101, 239);
  }
}

.headerMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.aboutShow {
  font-size: 16px;
  margin-bottom: 40px;
  line-height: 1.56;
  font-family: "Proxima Nova";
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 45%;
    white-space: pre-wrap;
  }

  @media (max-width: $mobile-max-size) {
    span {
      width: 80%;
      font-size: 12px;
      padding-top: 10px;
      text-align: center;
    }
  }
}
