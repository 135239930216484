@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "../../App.module.scss";

.rulesBody {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: $dark-gray3;
  font-family: "Proxima Nova";
  align-items: center;
}
