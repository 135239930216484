@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "../../App.module.scss";

.header {
  height: 40px;
  box-shadow: 0 2px 4px 0 rgb(216 216 216 / 50%);
  background-color: #fff;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;

  @media (max-width: $mobile-max-size) {
    margin-top: 6px;
    box-shadow: none;
    border-bottom: 1px solid rgb(216 216 216 / 50%);
  }

  .headerLogo {
    width: 60px;
    margin: 0 18px;
    display: flex;
    align-items: center;

    @media (max-width: $mobile-max-size) {
      width: 40px;
    }

    .headerLogoImage {
      height: 42px;

      @media (max-width: $mobile-max-size) {
        height: 32px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .leftContainer {
    flex: 0 0 auto;
    display: flex;
    color: $white;
    font-family: "OpenSans";
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    min-width: 200px;

    @media (max-width: $mobile-max-size) {
      min-width: 50px;
    }
  }

  .mainContainer {
    flex: 1 1 auto;
    display: flex;
    font-size: 22px;
    font-weight: 600;
    color: #b1b1bd;
    font-family: "OpenSans-Medium";
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .rightContainer {
    flex: 0 0 auto;
    display: flex;
    color: $white;
    font-family: "OpenSans";
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    min-width: 200px;

    @media (max-width: $mobile-max-size) {
      min-width: 50px;
    }
  }
}

.navItem {
  width: 120px;
  font-family: "OpenSans-SemiBold";
  font-size: 14px;
  color: #000;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;
  color: #999;

  @media (max-width: $mobile-max-size) {
    font-size: 10px;
    width: unset;
  }

  .navIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3px;
    color: #999;

    @media (max-width: $mobile-max-size) {
      font-size: 10px;
    }
  }

  &:hover {
    // color: $gray5;
    cursor: pointer;
    color: #eb0028;

    .navIcon {
      color: #eb0028;
    }
  }
}

.showImage {
  height: 24px;
  width: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex: 0 0 auto;
  opacity: 0.3;

  @media (max-width: $mobile-max-size) {
    width: 100px;
    height: 20px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
}
