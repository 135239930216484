@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "../../App.module.scss";

.showPreviewContainer {
  position: relative;
  overflow: visible;
  z-index: 10;

  &:hover {
    cursor: pointer;
    transform: scale(1.2);
    z-index: 15;
    transition: transform 1s;
  }
}

.showPreview {
  margin: 10px;

  @media (max-width: $mobile-max-size) {
    margin: 5px;
  }
}

.hoveredShowPreview {
  margin: 10px;
  display: flex;
  flex-direction: column;
  background-color: #181818;
  border-radius: 6px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 12;

  transform-origin: 50% 50%;
  transform: translateX(0px) translateY(0px) scaleX(1) scaleY(1) translateZ(0px)
    slow;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 3px 10px;
  // transition: transform 1.5s;

  // width: 328px;
  //   top: 663px;
  //   left: 419px;
  //   transform-origin: 50% 50%;
  //   transform: translateX(-55px) translateY(0px) scaleX(1) scaleY(1) translateZ(0px);
  //   box-shadow: rgba(0, 0, 0, 0.75) 0px 3px 10px;
  //   z-index: 3;
  //   opacity: 1;

  img {
    border-radius: 6px 6px 0 0;
  }

  @media (max-width: $mobile-max-size) {
    margin: 5px;
  }
}

.showMetadata {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 20px;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 auto;
  margin-bottom: 15px;

  .button {
    flex: 0 0 auto;

    &:last-child {
      margin-right: 0;
    }
  }

  .spacer {
    flex: 1 1 auto;
  }
}

.seasonInfo {
  //   border: 1px solid orange;
}

.showDescriptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  //   justify-content: center;
  font-family: "Roboto", sans-serif;
  font-size: 13px;

  .category {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;

    .categoryDivider {
      flex: 1 1 auto;
      margin: 0 7px;
      font-size: 8px;
      color: $gray3;
    }

    &:last-child {
      .categoryDivider {
        display: none;
      }
    }
  }
}

.showPreviewImage {
  width: 280px;
  height: 160px;
  flex: 0 0 auto;
  transition: transform 0.5s;
  border-radius: 6px 6px 0 0;

  @media (max-width: $mobile-max-size) {
    width: 140px;
    height: 80px;
  }
}
