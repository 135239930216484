@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "../../App.module.scss";

.buzzFeedQuiz {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: $dark-gray3;
  font-family: "Proxima Nova";
  height: 100%;
  width: 100%;
  align-items: center;
  overflow-y: auto;

  .body {
    flex: 1 1 auto;
    overflow-y: auto;
    display: flex;
    width: 80%;
    flex-direction: column;
    padding-bottom: 20px;
  }
}

.quizBadge {
  height: 22px;
  width: 22px;
  margin-right: 10px;
  display: flex;
}

.quizResultGradient {
  background-image: linear-gradient(
    180deg,
    #e40c78 0%,
    #e32 51.44%,
    #e40c78 100%
  );

  :global(.bp5-dialog-header) {
    background-color: #e40c78;
    color: $white;
    display: flex;
    justify-content: center;
  }

  :global(.bp5-dialog-footer) {
    background-color: #e40c78;
    color: $white;
    display: flex;
    justify-content: center;
  }
}

:global(.bp5-dialog) {
  width: 600px;

  @media (max-width: $mobile-max-size) {
    width: 90%;
  }
}

.metadata {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: $mobile-max-size) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.details {
  border: 1px solid blue;
}

.title {
  font-family: "proxima_nova_altbold";
  font-size: 32px;
  margin-bottom: 10px;

  @media (max-width: $mobile-max-size) {
    font-size: 20px;
    margin-bottom: 5px;
  }
}

.subtitle {
  font-family: "Proxima Nova";
  font-size: 20px;
  margin-bottom: 20px;

  @media (max-width: $mobile-max-size) {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.questionBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: $mobile-max-size) {
    margin-bottom: 10px;
  }
}

.quizFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;

  .buttonGroup {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

.byline {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  .bylineName {
    font-family: "Proxima Nova";
    font-size: 14px;
    margin-left: 5px;
    display: flex;
    flex-direction: row;

    .name {
      font-family: "proxima_nova_altbold";
      text-decoration: underline;
      margin-left: 5px;
    }
  }

  .bylineTitle {
    font-family: "Proxima Nova";
    font-size: 12px;
    margin-left: 5px;
    color: $dark-gray2;
  }
}
