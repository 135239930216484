@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "./ScottifyVariables.module.scss";

.scottifyBodyMobile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .categoryContainer {
    margin: 20px 5px;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;

    .categoryTitle {
      text-align: center;
      color: $white;
      font-family: "proxima_nova_altbold";
      font-size: 16px;
    }

    .categoryDescription {
      text-align: center;
      color: $spot-gray;
      font-family: "Proxima Nova";
      font-size: 10px;
    }

    .playlistsContainer {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      margin: 10px 0 0 0;
    }
  }
}

.mobileRosterButtonGroup {
  border-bottom: 1px solid $spot-gray;
  flex: 0 0 auto;
  align-items: center;
  padding: 2px;

  :global(.bp3-button) {
    border-radius: 0;
  }

  // to do
  // &.bp3-active {
  //   background-color: @white;
  //   .bp3-icon {
  //     color: @spot-green;
  //   }
  // }
}

.mobilePlaylistView {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .topButtonsContainer {
    flex: 0 0 auto;
    width: 100%;
    padding: 10px 10px 0 10px;
    // border: 1px solid blue;
    position: absolute;
    top: 38px;
    left: 0;
    z-index: 10;
    // background-color: $spot-bg;
  }

  .playlistScrollContainer {
    flex: 1 1 auto;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    align-items: center;
    padding: 15px;
    display: flex;
    // margin-top: 5px;
    flex-direction: column;

    background-color: rgb(162, 188, 142);
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.9) 70%,
      rgba(0, 0, 0, 0.7) 80%,
      rgba(0, 0, 0, 0.3) 90%,
      rgba(0, 0, 0, 0.2) 95%,
      rgba(0, 0, 0, 0.2) 100%
    );

    .controlContainer {
      flex: 1 1 auto;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      margin-top: -20px;
      margin-bottom: 5px;

      .controlPlay {
        background-color: $spot-green;
        border-radius: 50%;
        padding: 10px;
        margin-left: 5px;
      }
    }

    .playlistDesc {
      color: $spot-gray;
      font-family: "Proxima Nova";
      font-size: 14px;
      margin-bottom: 15px;
      // border-bottom: 1px solid $dark-gray3;
      padding: 20px;
      text-align: center;
    }

    .albumCover {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 5px;

      .albumCoverTitle {
        text-align: center;
        margin-top: 15px;
        color: $white;
        font-family: "proxima_nova_ltsemibold";
        font-size: 16px;
      }
    }
  }
}

:global(.bp3-menu) {
  color: $white;
  font-family: "proxima_nova_ltsemibold";
  background-color: $spot-black !important;
  font-size: 12px;
  min-width: 50px;
}

.playMenu {
  // font-family: "Proxima Nova";
  // color: $white !important;
  // font-size: 12px;
  // min-width: 50px;
}

.mobileTracksList {
  flex: 1 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}
