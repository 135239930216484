@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";

.showButton {
  border: 2px solid #919191;
  background-color: #232323;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  margin-right: 6px;
  //   flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;

  &.likeButton {
  }

  &.playButton {
    border: 2px solid $white;
    background-color: $white;
    color: $black;
  }

  &.chevronButton {
    align-self: flex-end;
  }
}

.likeButtonIcon {
  svg {
    width: 16px;
    height: 16px;
  }
}

.playButtonIcon {
  svg {
    margin-left: 3px;
    width: 22px;
    height: 22px;
  }
}
