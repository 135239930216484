@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "./ScottifyVariables.module.scss";

.durationMarker {
  font-family: "Proxima Nova";
  margin: 0 10px;
}

.mobileBottomBar {
  border-top: 1px solid $spot-bg;
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .bottomButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Proxima Nova";
    color: $white;
    font-size: 10px;

    .buttonIcon {
      margin-bottom: 3px;
    }

    &.disabled {
      color: $gray1;
    }
  }
}

.mainTrackTitle {
  margin-top: 5px;
  font-family: "Proxima Nova";
  color: $white;
}

.mainTrackArtist {
  color: $spot-gray;
  margin-left: 5px;
}

.controlsBarMobile {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;

  .progressBar {
    width: 100%;

    :global(.bp3-progress-bar) {
      border-radius: 0px;

      :global(.bp3-progress-meter) {
        background-color: $white;
        border-radius: 0px;
      }
    }
  }

  .mainControls {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    .controlIcon {
      margin: 0 10px;

      &.heart {
        color: $spot-green;
      }

      &.play {
        // border: 1px solid $white;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        margin-left: 2px;
      }
    }

    .title {
      flex: 1 1 auto;
      font-family: "Proxima Nova";
      color: $white;
      text-align: center;
      font-size: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .titleIcon {
        flex: 0 0 auto;
        margin-left: 2px;
        margin-right: 0;
      }

      .mainTrackArtist {
        margin-left: 2px;
      }
    }
  }
}

.controlsBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;

  .controlIcon {
    margin: 0 15px;

    &.play {
      border: 1px solid $light-gray1;
      border-radius: 50%;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1px 6px;
      color: $light-gray1;
      padding-left: 1px;

      :global(.bp3-icon) {
        margin-left: 2px;
      }

      &:hover {
        border: 2px solid $white;
        width: 28px;
        height: 28px;
        margin: 0;
        cursor: pointer;
        color: $white;
      }
    }
  }
}

.progressBar {
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    :global(.bp3-progress-bar) {
      :global(.bp3-progress-meter) {
        background-color: $spot-green;
      }
    }
  }

  :global(.bp3-progress-bar) {
    height: 4px;
    background-color: #535353;

    :global(.bp3-progress-meter) {
      background-color: #b3b3b3;
    }
  }
}
