@import "../node_modules/@blueprintjs/core/lib/scss/variables.scss";

$mobile-max-size: 767px;

.scottSecretSantaApp {
  font-family: "Lato";
  height: 100%;
  width: 100%;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

button:focus {
  outline: 0;
}

:global(.bp4-overlay):focus {
  outline: 0;
}

:global(.bp4-overlay-open):focus {
  outline: 0;
}

:global(.bp4-portal):focus {
  outline: 0;
}

:global(body) {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

:global(html) {
  width: 100%;
  height: 100%;
}
