@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "./ScottifyVariables.module.scss";

.scottifyBody {
  flex: 0 1 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  .bodyTop {
    background-color: $spot-black;
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow-y: hidden;

    .sideNav {
      padding: 0 20px;

      .rosterControlButton {
        height: 34px;
        width: 34px;
        border-radius: 50%;
        border: 1px solid $light-gray1;
        background-color: $spot-black;
        margin: 1px;

        :global(.bp3-icon) {
          color: $light-gray1;
        }

        // bmoore - to do
        // &:not(.bp3-disabled) {
        //   &:hover {
        //     height: 36px;
        //     width: 36px;
        //     border: 2px solid @white;
        //     .bp3-icon {
        //       color: @white;
        //     }

        //     margin: 0;
        //     cursor: pointer;
        //   }
        // }
      }
    }

    .content {
      padding: 20px 0;
      height: 100%;
      flex: 1 1 auto;
      display: flex;
    }

    .contentLeft {
      display: flex;
      flex: 0 1 auto;
      height: 100%;
      width: 40%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-right: 10px;

      .playlistCoverContainer {
        height: 100%;
        width: 100%;
        max-height: 300px;
        max-width: 300px;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        flex: 0 1 auto;
      }
    }

    .playlistText {
      margin-bottom: 25px;
      width: 100%;
      font-family: "Proxima Nova";

      .playlistTitleHeader {
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 10px;
      }

      .playlistTitle {
        font-size: 34px;
        color: $white;
      }

      .playlistDescription {
        font-size: 12px;
      }
    }

    .contentRight {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      font-family: "Proxima Nova";
      padding-right: 15px;

      .tracksTable {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
      }

      .tracksTableBody {
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        overflow-y: scroll;
        flex-direction: column;
      }
    }
  }

  .bodyBottom {
    flex: 0 0 auto;
    height: 250px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    align-items: center;
    margin: 0 20px;
  }
}

.tableRow {
  border-bottom: 1px solid $spot-bg-light;
  display: flex;
  flex-direction: row;
  color: $white;

  &.tracksTableHeader {
    color: $spot-gray;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    padding-bottom: 5px;
  }

  &.tracksTableRow {
    padding: 8px 0;
    align-items: center;

    &:hover {
      background-color: $spot-bg-light;

      .colPlay {
        visibility: visible;
      }
    }
  }

  .colPlay {
    flex: 0 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    visibility: hidden;

    .blankIcon {
      height: 24px;
      width: 24px;
      padding-left: 2px;
      margin: 1px;
    }

    .playIcon {
      border: 1px solid $light-gray1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      height: 24px;
      width: 24px;
      padding-left: 2px;
      background-color: $spot-black;
      margin: 1px;
      color: $light-gray1;

      &:hover {
        border: 2px solid $white;
        height: 26px;
        width: 26px;
        cursor: pointer;
        margin: 0;
        color: $white;
      }
    }

    .heartIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      padding-left: 2px;
      margin: 1px;
      color: $light-gray1;
    }
  }

  .colTitle {
    flex: 1 1 auto;
    margin-left: 10px;
  }

  .colArtist {
    width: 400px;
    text-align: center;
    padding-right: 40px;
  }

  .colDuration {
    width: 10%;
    color: $spot-gray;
  }

  .colTrack {
    width: 70px;
    color: $spot-gray;
    // text-align: left;

    &.center {
      padding-left: 12px;
    }
  }
}

.scottifyBodyNew {
  flex: 0 1 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
}

.leftSidePanel {
  width: 220px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  background-color: #000;
}

.logoContainer {
  display: flex;
  padding: 20px 15px;

  .scottifyLogo {
    height: 38px;
  }
}

.mainPanel {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  position: relative;
}

.mainPanelHeader {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  flex: 0 0 auto;
  background-color: transparent;
  z-index: 1;

  .headerLeft {
    flex: 1 1 auto;
    padding-left: 15px;
  }

  .headerRight {
    flex: 0 0 auto;
    padding-right: 15px;
  }

  .premiumTag {
    color: $white;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 20px;
    border: 1px solid $white;
    padding: 10px 40px;
    font-size: 12px;
  }
}

.headerNavButton {
  margin-right: 15px;
  border: 1px solid $black;
  border-radius: 50%;
  color: $white;
  padding: 6px;
  background-color: $black;

  :hover {
    color: $white;
    cursor: pointer;
  }

  &.disabled {
    color: $dark-gray5;
    :hover {
      color: $dark-gray5;
      cursor: no-drop;
    }
  }

  &.bordered {
    border: 1px solid $white;
    border-radius: 50%;
    color: $white;
    padding: 6px;
    background-color: $black;
    opacity: 0.9;

    &.disabled {
      opacity: 0.5;
    }
  }
}

.mainPanelContent {
  flex: 1 1 auto;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  z-index: 1;

  .categoryContainer {
    margin: 0px 5px 20px 5px;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;

    .categoryTitle {
      color: $white;
      font-family: "proxima_nova_altbold";
      font-size: 24px;
      margin-left: 15px;
    }

    .categoryDescription {
      color: $spot-gray;
      margin-left: 14px;
      font-family: "Proxima Nova";
      font-size: 10px;
    }

    .playlistsContainer {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      margin: 10px 0;

      .albumCoverContainer {
        margin-right: 15px;
        background-color: #181818;
        border-radius: 5px;
        color: $white;
        position: relative;

        .albumPlay {
          position: absolute;
          bottom: 60px;
          right: 30px;
          visibility: hidden;
          opacity: 0;
          transition: visibility 0.3s linear, opacity 0.3s linear;

          .albumPlayIcon {
            background-color: $spot-green;
            padding: 10px;
            border-radius: 50%;
          }
        }

        &:hover {
          background-color: #282828;
          cursor: pointer;

          .albumPlay {
            visibility: visible;
            opacity: 1;
            animation: example2 0.3s ease-out;
          }
        }
      }
    }
  }
}

@keyframes example2 {
  0% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0%);
  }
}

.sidePanelButtons {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .sidePanelButton {
    margin: 5px 8px 0 8px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-radius: 3px;
    font-weight: 600;

    &.selected {
      background-color: #272727;
      color: $white;
    }

    .sidePanelButtonIcon {
      margin-right: 18px;
    }
  }
}

.albumsView {
  padding: 15px;
}

.playlistViewContainer {
  position: relative;
}

.playlistViewBackground {
  position: absolute;
  background-color: rgb(162, 188, 142);
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0)
  );
  top: 0;
  left: 0;
  height: 50%;
  width: 100%;
  z-index: 0;
}

.playlistView {
  display: flex;
  flex-direction: column;
}

.playlistViewHeader {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  z-index: 1;

  .albumPreviewContainer {
    width: 300px;
    flex: 0 0 auto;
  }
  .albumMetadataContainer {
    flex: 1 1 auto;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    padding: 0 100px 40px 0;

    .metadataHeader {
      color: $white;
      font-size: 12px;
      font-family: "Proxima Nova";
      letter-spacing: 1px;
      margin-bottom: -10px;
    }

    .metadataTitle {
      color: $white;
      font-size: 60px;
      font-family: "proxima_nova_altbold";
    }

    .metadataDescription {
      color: $light-gray1;
      font-size: 16px;
      font-family: "Proxima Nova";
    }
  }
}

.playlistViewContent {
  // border: 1px solid gold;
  z-index: 1;
  // background-color: $black;
  // rgba(0, 0, 0, 0),
  // rgba(0, 0, 0, 0.1),
  // rgba(0, 0, 0, 0.2),
  // rgba(0, 0, 0, 0.3)
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.9) 70%,
    rgba(0, 0, 0, 0.7) 80%,
    rgba(0, 0, 0, 0.3) 90%,
    rgba(0, 0, 0, 0.2) 95%,
    rgba(0, 0, 0, 0.2) 100%
  );
}

.controlContainer {
  display: flex;
  flex-direction: row;
  padding: 15px 20px;
  // border: 1px solid blue;

  .controlPlay {
    background-color: $spot-green;
    border-radius: 50%;
    padding: 15px;
    margin-left: 5px;

    &:hover {
      transform: scale(1.05);
      cursor: pointer;
    }
  }

  .controlMore {
    // background-color: $spot-green;
    border-radius: 50%;
    padding: 18px;
    margin-left: 10px;

    &:hover {
      // transform: scale(1.05);
      cursor: not-allowed;
    }
  }
}
