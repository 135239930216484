@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "./ScottifyVariables.module.scss";

.albumCoverMobile {
  padding: 5px;
  margin: 3px;

  &.mobile {
    padding: 0 10px;
    flex: 0 0 auto;

    :hover {
      cursor: pointer;
    }

    &.isSelected {
      border-bottom: 3px solid $spot-green;
    }
    &.large {
      .albumCoverImage {
        height: 180px;
        width: 180px;
      }
    }

    .albumCoverImage {
      height: 130px;
      width: 130px;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .albumCoverTitle {
      text-align: center;
      margin-top: 5px;
      color: $white;
      font-family: "Proxima Nova";
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 130px;

      &.longTitle {
        margin-top: 7px;
        font-size: 12px;
      }

      &.includeFull-title {
        width: 100%;
      }
    }
  }

  :hover {
    cursor: pointer;
  }

  &.isSelected {
    border-bottom: 3px solid $spot-green;
  }

  .albumCoverImage {
    height: 160px;
    width: 160px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .albumCoverTitle {
    text-align: center;
    margin-top: 5px;
    font-family: "Proxima Nova";
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;

    &.longTitle {
      margin-top: 7px;
    }
  }
}
