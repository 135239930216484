@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "./ScottifyVariables.module.scss";

.playlistAlbumCover {
  padding: 20px;
  margin: 10px;

  .albumCoverImage {
    height: 250px;
    width: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .albumCoverTitle {
    text-align: center;
    margin-top: 5px;
    font-family: "Proxima Nova";
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;

    &.longTitle {
      margin-top: 7px;
    }
  }
}
