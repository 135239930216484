@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "../../App.module.scss";

.scottflixHomeContainer {
  flex: 1 1 auto;
  width: 100%;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 120px;

  @media (max-width: $mobile-max-size) {
    padding-bottom: 50px;
  }
}

.categoriesList {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  z-index: 12;
  padding: 20px 0;

  @media (max-width: $mobile-max-size) {
    padding: 5px 0;
  }
}
