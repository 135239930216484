@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "../../App.module.scss";

.questionContainerText {
  width: 60%;

  @media (max-width: $mobile-max-size) {
    width: 100%;
  }

  &:not(:last-child) {
    margin-bottom: 50px;

    @media (max-width: $mobile-max-size) {
      margin-bottom: 20px;
    }
  }
}

.question {
  background-color: $turquoise2;
  color: $white;
  font-family: "proxima_nova_altbold";
  font-size: 20px;
  padding: 15px;

  @media (max-width: $mobile-max-size) {
    font-size: 14px;
    padding: 8px;
  }
}

.answerBlock {
  border: 2px solid $light-gray3;
  display: flex;
  flex-direction: column;

  &.imageBlock {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: space-evenly;
  }
}

.answer {
  background-color: $white;
  border-bottom: 2px solid $light-gray3;
  color: $dark-gray5;
  font-family: "proxima_nova_altbold";
  font-size: 18px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;

  @media (max-width: $mobile-max-size) {
    font-size: 12px;
    padding: 6px;
  }

  &.imageAnswer {
    border-bottom: none;
  }

  &.selected {
    background-color: $turquoise3;
    color: $white;

    &:hover {
      background-color: $turquoise3;
      color: $white;
    }
  }

  &:hover {
    color: $turquoise3;
    cursor: pointer;
  }

  &:last-child {
    border-bottom: none;
  }
}

.imageContainer {
  height: 200px;
  width: 200px;

  @media (max-width: $mobile-max-size) {
    height: 80px;
    width: 80px;
  }

  .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
  }
}
