@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "../../App.module.scss";

.badgeContainer {
  height: 40px;
  width: 40px;
  margin-right: 6px;
  display: flex;

  .image {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
  }

  @media (max-width: $mobile-max-size) {
    height: 25px;
    width: 25px;
    margin-right: 4px;
  }
}
