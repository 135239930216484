@import "../../../node_modules/@blueprintjs/core/lib/scss/variables.scss";
@import "../../App.module.scss";

.rulesBody {
  flex: 1 1 auto;
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 50px;
  justify-self: center;
  align-self: center;

  :first-child {
    margin-top: 0px;
    padding: 10px 0;
  }

  @media (max-width: $mobile-max-size) {
    width: unset;
    margin: 0 20px 20px 20px;
    justify-content: flex-start;
  }

  .ruleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: $mobile-max-size) {
      margin: 15px 20px;
      &:last-child {
        margin-bottom: 60px;
      }
    }

    &:hover {
      .ruleImageContainer {
        background-color: rgb(223, 28, 47);
        border: 2px solid rgb(223, 28, 47);
      }

      .ruleHeader {
        border: 1px solid rgb(223, 28, 47);
        color: rgb(223, 28, 47);
      }
    }

    .ruleImageContainer {
      border: 2px solid $black;
      background-color: $black;
      width: 80px;
      height: 80px;
      margin-right: 40px;
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;

      @media (max-width: $mobile-max-size) {
        margin-right: 15px;
        width: 30px;
        height: 30px;
      }

      .ruleImage {
        flex: 0 0 auto;
        width: 70%;
      }
    }

    .ruleHeader {
      font-weight: bold;
      font-family: "OpenSans-Bold";
      font-size: 10px;
      color: $black;
      border: 1px solid $black;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px 0 0 2px;
      text-transform: uppercase;
      text-orientation: upright;
      writing-mode: vertical-rl;
      background-color: $white;
      font-weight: 700;
      padding: 3px;

      @media (max-width: $mobile-max-size) {
        font-weight: bold;
        font-family: "BrandonText-Bold";
        color: rgb(24, 26, 29);
        border: 1px solid rgb(24, 26, 29);
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px 2px 0 0;
        width: 100%;
        text-transform: uppercase;
        letter-spacing: 2px;
        writing-mode: lr;
        background-color: $white;
        padding: 1px;
        font-size: 8px;
      }
    }

    .ruleText {
      background-color: rgb(245, 247, 249);
      // border: 3px solid $black;
      text-align: center;
      // background-color: $dark-gray1;
      border-radius: 5px;
      transition: transform 0.5s;
      font-family: "OpenSans";
      font-weight: 500;
      letter-spacing: 0.5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1 1 auto;

      @media (max-width: $mobile-max-size) {
        font-size: 9px;
        display: flex;
        flex-direction: column;
      }

      .ruleDescription {
        padding: 20px;

        @media (max-width: $mobile-max-size) {
          padding: 10px;
        }
      }
    }
  }
}
